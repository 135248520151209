<template>
    <div class="video-upload">
        <asc-header></asc-header>
        <div class="content">
            <asc-sidebar></asc-sidebar>
            <div class="main-container">
                <div class="title">
                    <div>{{ title[$route.path] }}<span></span></div>
                </div>
                <router-view></router-view>
            </div>
        </div>
        <qilv-footer themes="light"></qilv-footer>
    </div>
</template>

<script>
    export default {
        name: "AscMain",
        data() {
            return {
                title: {
                    '/asc/video_upload': '发布视频',
                    '/asc/video_list': '内容管理',
                    '/asc/content_standard': '内容规范',
                    '/asc/contact_us': '联系我们'
                },
                toast: null,
                message: '上传错误，请重试，谢谢！',
            }
        }
    }
</script>

<style scoped lang="less">
    .video-upload {
        display:flex;
        flex-direction:column;
        .content {
            flex:1;
            display:flex;
            gap:0 24px;
            padding:24px 0;
            .main-container {
                flex:1;
                display:flex;
                flex-direction:column;
                background-color:white;
                border-radius:10px;
                position:relative;
                .title {
                    padding:40px 40px 0 40px;
                    div {
                        display:inline-block;
                        font-size:24px;
                        font-weight:500;
                        color:#3F3F3F;
                        line-height:32px;
                        span {
                            content:" ";
                            display:block;
                            width:inherit;
                            height:4px;
                            border-radius:2px;
                            background-color:#F0F0F0;
                            margin-top:8px;
                        }
                    }
                }
            }
        }
    }
</style>